import {OnEventFn} from '@wix/yoshi-flow-editor'

import {
  EDITOR_OPEN_TABLE_RESERVATION_WIDGET_SETTINGS_PANEL,
  openWidgetSettingsPanel,
} from './panels'

export const onEvent: OnEventFn = (event, editorSDK) => {
  switch (event?.eventPayload?.id) {
    case EDITOR_OPEN_TABLE_RESERVATION_WIDGET_SETTINGS_PANEL:
      openWidgetSettingsPanel(editorSDK, event.eventPayload.componentRef)
      break
    default:
      break
  }
}
