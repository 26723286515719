import type {
  FlowEditorSDK,
  EditorScriptFlowAPI,
  ManagementActionsBuilder,
  TFunction,
} from '@wix/yoshi-flow-editor'

import {APP_DEF_ID, HELP_ARTICLE_IDS, PARENT_APP_DEF_ID} from '../utils/constants'

import {openReservationsBmOverEditor, openSettingsBmOverEditor} from './panels'

const PanelType = {
  AddPanel: 'addPanel',
  PagesPanel: 'pagesPanel',
} as const

export const configureMyBusinessActions = (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  managementActionsBuilder: ManagementActionsBuilder,
) => {
  const {translations} = flowAPI
  const t = translations.t as TFunction

  managementActionsBuilder.mainActions().addAction({
    title: t('editor-reservations.my-business.main-actions.setup-table-reservations'),
    icon: 'appManager_settingsAction',
    onClick: async () => {
      await openSettingsBmOverEditor(editorSDK)
    },
  })

  managementActionsBuilder.customActions().addAction(
    {
      title: t('editor-reservations.my-business.custom-actions.manage-reservations'),
      icon: 'restaurantsTableReservations',
      type: 'dashboard',
      onClick: async () => {
        await openReservationsBmOverEditor(editorSDK)
      },
    },
    {
      title: t('editor-reservations.my-business.custom-actions.add-reservation-elements'),
      icon: 'appManager_addElementsAction',
      type: 'editorActions',
      onClick: async () => {
        const token = 'TOKEN'
        const {check, show} = editorSDK.editor.deeplink

        const addElementsPanel = {
          type: PanelType.AddPanel,
          named: {
            appDefinitionId: APP_DEF_ID,
          },
        }

        const editorDeepLinkToAddElementsPanel = await check(token, addElementsPanel)

        editorDeepLinkToAddElementsPanel && (await show(token, addElementsPanel))
      },
    },
    {
      title: t('editor-reservations.my-business.custom-actions.manage-pages'),
      icon: 'appManager_pagesAction',
      type: 'editorActions',
      onClick: async () => {
        const token = 'TOKEN'
        const {check, show} = editorSDK.editor.deeplink
        const pagesPanel = {
          type: PanelType.PagesPanel,
          named: {
            appDefinitionId: PARENT_APP_DEF_ID,
          },
        }

        const editorDeepLinkToPagesPanel = await check(token, pagesPanel)

        editorDeepLinkToPagesPanel && (await show(token, pagesPanel))
      },
    },
  )

  managementActionsBuilder.learnMoreAction().set({
    id: HELP_ARTICLE_IDS.reservationsGfpp,
  })
}
